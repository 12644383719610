import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { supabase } from '../client'
import Modal from 'react-bootstrap/Modal';
import latinize from 'latinize';
import Alert from 'react-bootstrap/Alert';
import { underline } from '@uiw/react-markdown-editor';

export const Manage = ({ redirect, ...rest }) => {
  const [projects, setProjects] = useState();
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [type, setType] = useState('');
  const [mainImg, setMainImg] = useState();
  const [images, setImages] = useState([]);
  const [fakeImages, setFakeImages] = useState([]);
  const [show, setShow] = useState(false);
  const [showSuccess, setShowSucces] = useState();
  const [deleteQuestion, setDeleteQuestion] = useState({
    show: false,
    urlToRemove: "",
    title: ""
  });
  const [editing, setEditing] = useState(false)
  //const [urlToDelete, setUrlToDelete] = useState();

  const handleFileChange = (event) => {
    const imageFiles = event.target.files[0];
    const fake = URL.createObjectURL(event.target.files[0]);
    setImages([...images, imageFiles]);
    setFakeImages([...fakeImages, fake])
    event.target.value = ''; // Очищаем input
  };

  async function getProjects() {
    let { data: projects, error } = await supabase
      .from('projects')
      .select('*')
    setProjects(projects)
  }

  useEffect(() => {
    getProjects()
  }, [])

  const handleClose = (to) => {
    if (to !== "clear") {
      if (!editing) {
        uploadProject();
      } else {
        editProject(editing)
      }
    } else {
      setEditing(undefined)
      setTitle('')
      setDesc('')
      setType('')
      setMainImg(undefined)
      setImages([])
      setFakeImages([])
    }
    setEditing(undefined)
    setShow(false);
  };
  const newProjForm = () => {
    setShow(true)
  };

  function parseDate(dateString) {
    let parsed = new Date(dateString.replace(' ', 'T'))
    return ("0" + parsed.getDate()).slice(-2) + "." + ("0" + parsed.getMonth()).slice(-2) + "." + parsed.getFullYear() + ", " + ("0" + parsed.getHours()).slice(-2) + ":" + ("0" + parsed.getMinutes()).slice(-2) + ":" + ("0" + parsed.getSeconds()).slice(-2)
  }

  async function uploadFile(file) {
    if (file !== undefined && file.name!==undefined) {
      const { data, error } = await supabase
        .storage
        .from('projects_images')
        .upload(latinize(`${latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_')}/${file.name.replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_')}`), file, {
          cacheControl: '3600',
          upsert: false
        })
      if (error) {
        if (error.message!== "The resource already exists"){
          setShowSucces({
            title: "Ошибка",
            text: "Не удалось загрузить фотографии на сервер. Подробнее: " + error.message,
          })
        }
      }
    }
  }

  async function uploadProject() {
    if (title.trim() !== '' && desc.trim() !== '' && type.trim() !== '' && mainImg && images) {
      [...images, mainImg].forEach(uploadFile)
      const { data, error } = await supabase
        .from('projects')
        .insert([
          {
            title: title.trim(),
            desc: desc.trim(),
            type: type,
            url: latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_'),
            main_img: 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/projects_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + "/" + latinize(mainImg.name).replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_'),
            images: images.map((item) => 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/projects_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + "/" + item.name.replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_'))
          },
        ])
        .select()
      if (!error) {
        setTitle('')
        setDesc('')
        setType('')
        setMainImg(undefined)
        setImages([])
        setFakeImages([])
        handleClose("clear")
        setShowSucces({
          title: "Опубликовано",
          text: "Проект успешно опубликован и уже доступен на сайте"
        })
        getProjects()
      } else {
        console.log(error)
        setShowSucces({
          title: "Ошибка",
          text: "Что-то пошло не так. Детали: " + error.message,
        })
      }
    } else {
      alert("Вы не заполнили все поля")
    }
  }

  function callDelete(url, title, data) {
    setDesc(data.desc)
    setTitle(data.title)
    setType(data.type)
    setFakeImages(data.images)
    setImages(data.images)
    setMainImg(data.main_img)
    setEditing(data.url)
    setDeleteQuestion({
      show: true,
      urlToRemove: url,
      title: title
    })
  }

  async function deleteImgServer(url){
    const { data, error } = await supabase
    .storage
    .from('projects_images')
    .remove([latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + '/' + url.split('projects_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + '/')[1]])
    if(error){
      console.log(error)
    }
    //console.log([latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + '/' + url.split('projects_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + '/')[1]])
  }

  async function deleteProject(url) {
    [...images, mainImg].forEach(deleteImgServer)
    console.log([...images, mainImg])
    const { error } = await supabase
      .from('projects')
      .delete()
      .eq('url', url)

    setDeleteQuestion({
      show: false,
      urlToRemove: "",
      title: ""
    })
    if (error) {
      setShowSucces({
        title: "Ошибка",
        text: "Что-то пошло не так. Детали: " + error.message,
      })
    } else {
      setProjects(projects.filter(item => item.url !== url))
      setShowSucces({
        title: "Удалено",
        text: "Проект " + url + " был успешно удален",
      })
    }
  }

  function callEdit(data) {
    setShow(true)
    setDesc(data.desc)
    setTitle(data.title)
    setType(data.type)
    setFakeImages(data.images)
    setImages(data.images)
    setMainImg(data.main_img)
    setEditing(data.url)
  }

  async function editProject(url) {
    [...images, mainImg].forEach(uploadFile)
    //const fileUrls = [];
    //for (let i = 0; i < [...images, mainImg].length; i++) {
    //  fileUrls.push([...images, mainImg][i].name);
    //}
    const { data, error } = await supabase
      .from('projects')
      .update([
        {
          title: title.trim(),
          desc: desc.trim(),
          type: type,
          main_img: mainImg.name!== undefined ? 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/projects_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + "/" + latinize(mainImg.name).replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') : mainImg,
          images: images.map((item) => item.name!==undefined ? 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/projects_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + "/" + latinize(item.name).replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') : item)
        },
      ])
      .eq('url', url)
      .select()
    if (!error) {
      setEditing(undefined)
      setTitle('')
      setDesc('')
      setType('')
      setMainImg(undefined)
      setImages([])
      setFakeImages([])
      setShowSucces({
        title: "Отредактировано",
        text: "Проект успешно отредактирован и изменения уже видны на сайте"
      })
      getProjects()
    }
  }
  async function removeImg(url, e){
    e.preventDefault();
    setFakeImages(fakeImages.filter(item => item !== url))
    setImages(images.filter(item => item !== url))
  }

  return (
    <div>
      <Modal show={deleteQuestion.show} data-bs-theme="dark">
        <Modal.Header>
          <Modal.Title style={{ color: 'white' }}>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span style={{ color: 'white' }}>Вы уверены что хотите удалить проект "{deleteQuestion.title}"? Это действие невозможно отменить</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteQuestion({
            show: false,
            urlToRemove: "",
            title: ""
          })}>
            Отмена
          </Button>
          <Button variant="danger" onClick={() => deleteProject(deleteQuestion.urlToRemove)}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={() => handleClose("clear")} data-bs-theme="dark" >
        <Modal.Header closeButton>
          {!editing && <Modal.Title style={{ color: 'white' }}>Новый проект</Modal.Title>}
          {editing && <Modal.Title style={{ color: 'white' }}>Редактирование проекта</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          <span style={{ color: 'white' }}>Заполните все поля для добавления нового проекта</span>
          <br /><br />
          <Form>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Название</Form.Label>
              <Form.Control type="email" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Введите название" />
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicPassword">
              <Form.Label>Описание</Form.Label>
              <Form.Control as="textarea" value={desc} onChange={(e) => setDesc(e.target.value)} placeholder="Введите описание" />
              <Form.Text className="text-muted">
                Рекомендуется не более 150 символов
              </Form.Text>
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3">
              <Form.Label>Тип</Form.Label>
              <Form.Select aria-label="Default select example" value={type} onChange={(e) => setType(e.target.value)}>
                <option hidden>Выберите тип</option>
                <option value="Жилые пространства">Жилые пространства</option>
                <option value="Общественные пространства">Общественные пространства</option>
                <option value="Частные дома">Частные дома</option>
                <option value="Outdoor">Outdoor</option>
                <option value="Рестораны">Рестораны</option>
              </Form.Select>
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Главное фото</Form.Label>
              <Form.Control type="file" onChange={(e) => setMainImg(e.target.files[0])} />
              <Form.Text className="text-muted">
                Если вы выберите новый файл, то он заменит текущее главное фото на новое выбранное
              </Form.Text>
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Фотографии</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
              <div className="images-to-upload">
                {fakeImages.map((image, index) => (
                  <div className="img-card" key={index}>
                    <button className='remove-img' onClick={(e) => removeImg(image, e)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /><line x1="10" y1="11" x2="10" y2="17" /><line x1="14" y1="11" x2="14" y2="17" /></svg>
                    </button>
                    <img key={index} src={image} alt={`Сломанное фото`} style={{ width: '100px', height: '100px', margin: '8px', borderRadius: "3px", objectFit: "cover" }} />
                  </div>
                ))}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {!editing && <Button variant="primary" onClick={uploadProject}>
            Опубликовать
          </Button>}
          {editing && <Button variant="primary" onClick={handleClose}>
            Опубликовать
          </Button>}
        </Modal.Footer>
      </Modal>
      <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
        <Container>
          <Navbar.Brand>Админ панель</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <span style={{ cursor: "pointer" }} className="nav-link">Проекты</span>
              <Link style={{ cursor: "pointer" }} className="nav-link" to="/blogs">Блоги</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container data-bs-theme="dark">
        <br />
        <h2 style={{ color: 'white' }}>
          Проекты
        </h2>
        <p style={{ color: 'gray' }}>Представленная ниже таблица отображает список все проектов на сайте.</p>
        <br />
        <Alert show={showSuccess !== undefined ? true : false} variant="warning">
          <Alert.Heading>{showSuccess !== undefined && showSuccess.title}</Alert.Heading>
          <p>
            {showSuccess !== undefined && showSuccess.text}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShowSucces(undefined)} variant="outline-warning">
              Закрыть
            </Button>
          </div>
        </Alert>
        {projects !== undefined && <Table striped bordered hover>
          <thead>
            <tr>
              <th>Название проекта</th>
              <th>Дата добавления</th>
              <th>Тип</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((proj, index) =>
              <tr key={index}>
                <td key={index + 1}>{proj.title}</td>
                <td key={index + 2}>{parseDate(proj.date)}</td>
                <td key={index + 3}>{proj.type}</td>
                <td>
                  <Button size="sm" variant="outline-primary" onClick={() => callEdit(proj)}>Редактировать</Button>
                  <Button size="sm" variant="outline-danger" className='ms-2' onClick={() => callDelete(proj.url, proj.title, proj)}>Удалить</Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>}
        <Button onClick={newProjForm}>+ Новый проект</Button>
      </Container>
    </div>
  )
}
