import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { supabase } from '../client'
import Modal from 'react-bootstrap/Modal';
import latinize from 'latinize';
import Alert from 'react-bootstrap/Alert';

export const Manage_Blogs = ({ redirect, ...rest }) => {
  const [blogs, setblogs] = useState();
  const [title, setTitle] = useState('');
  const [desc, setDesc] = useState('');
  const [type, setType] = useState('');
  const [mainImg, setMainImg] = useState();
  const [images, setImages] = useState([]);
  const [fakeImages, setFakeImages] = useState([]);
  const [deleteQuestion, setDeleteQuestion] = useState({
    show: false,
    urlToRemove: "",
    title: ""
  });
  const [showSuccess, setShowSucces] = useState();

  const handleFileChange = (event) => {
    const imageFiles = event.target.files[0];
    const fake = URL.createObjectURL(event.target.files[0]);
    setImages([...images, imageFiles]);
    setFakeImages([...fakeImages, fake])
    event.target.value = ''; // Очищаем input
  };

  async function getblogs() {
    let { data: blogs, error } = await supabase
      .from('blogs')
      .select('*')
    setblogs(blogs)
  }

  useEffect(() => {
    getblogs()
  }, [])

  const [show, setShow] = useState(false);

  const handleClose = () => {
    uploadProject();
    setShow(false);
  };
  const newProjForm = () => {
    setShow(true)
  };

  function parseDate(dateString) {
    if(dateString!==null){
      let parsed = new Date(dateString.replace(' ', 'T'))
    return ("0" + parsed.getDate()).slice(-2) + "." + ("0" + parsed.getMonth()).slice(-2) + "." + parsed.getFullYear() + ", " + ("0" + parsed.getHours()).slice(-2) + ":" + ("0" + parsed.getMinutes()).slice(-2) + ":" + ("0" + parsed.getSeconds()).slice(-2)
    }
  }

  async function uploadFile(file) {
    if (file !== undefined) {
      const { data, error } = await supabase
        .storage
        .from('blogs_images')
        .upload(latinize(`${title.replace(/[ ?]/g, '_')}/${file.name.replace(/[ ?]/g, '_')}`), file, {
          cacheControl: '3600',
          upsert: false
        })
    }
  }

  async function uploadProject() {
    [...images, mainImg].forEach(uploadFile)
    //const fileUrls = [];
    //for (let i = 0; i < [...images, mainImg].length; i++) {
    //  fileUrls.push([...images, mainImg][i].name);
    //}
    const { data, error } = await supabase
      .from('blogs')
      .insert([
        {
          title: title.trim(),
          desc: desc.trim(),
          type: type,
          url: latinize(title).replace(/[ ?]/g, '_'),
          main_img: 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/blogs_images/' + latinize(title).replace(/[ ?]/g, '_') + "/" + latinize(mainImg.name).replace(/[ ?]/g, '_'),
          images: images.map((item) => 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/blogs_images/' + latinize(title).replace(/[ ?]/g, '_') + "/" + item.name.replace(/[ ?]/g, '_'))
        },
      ])
      .select()
    if (!error) {
      setTitle('')
      setDesc('')
      setType('')
      setMainImg(undefined)
      setImages([])
      setFakeImages([])
    }
  }

  function callDelete(url, title) {
    console.log(url, title)
    setDeleteQuestion({
      show: true,
      urlToRemove: url,
      title: title
    })
  }

  async function deleteProject(url) {
    setblogs(blogs.filter(item => item.url !== url))

    const { error } = await supabase
      .from('blogs')
      .delete()
      .eq('url', url)
      
      setDeleteQuestion({
        show: false,
        urlToRemove: "",
        title: ""
      })
      setShowSucces({
        title: "Удалено",
        text: "Блог " + url + " был удален",
      })
      
  }
  return (
    <div>
      <Modal show={deleteQuestion.show} data-bs-theme="dark">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'white' }}>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span style={{ color: 'white' }}>Вы уверены что хотите удалить проект "{deleteQuestion.title}"? Это действие невозможно отменить</span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteQuestion({
            show: false,
            urlToRemove: "",
            title: ""
          })}>
            Отмена
          </Button>
          <Button variant="danger" onClick={() => deleteProject(deleteQuestion.urlToRemove)}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show} data-bs-theme="dark">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'white' }}>Новый проект</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span style={{ color: 'white' }}>Заполните все поля для добавления нового проекта</span>
          <br /><br />
          <Form>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Название</Form.Label>
              <Form.Control type="email" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Введите название" />
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicPassword">
              <Form.Label>Описание</Form.Label>
              <Form.Control as="textarea" value={desc} onChange={(e) => setDesc(e.target.value)} placeholder="Введите описание" />
              <Form.Text className="text-muted">
                Рекомендуется не более 150 символов
              </Form.Text>
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3">
              <Form.Label>Тип</Form.Label>
              <Form.Select aria-label="Default select example" onChange={(e) => setType(e.target.value)}>
                <option>Выберите тип</option>
                <option value="Жилые пространства">Жилые пространства</option>
                <option value="Общественные пространства">Общественные пространства</option>
                <option value="Частные дома">Частные дома</option>
                <option value="Jutdoor">Jutdoor</option>
                <option value="Рестораны">Рестораны</option>
              </Form.Select>
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Главное фото</Form.Label>
              <Form.Control type="file" onChange={(e) => setMainImg(e.target.files[0])} />
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Фотографии</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
              <div className="images-to-upload">
                {fakeImages.map((image, index) => (
                  <img key={index} src={image} alt={`upload-${index}`} style={{ width: '100px', height: '100px', margin: '8px', borderRadius: "3px", objectFit: "cover" }} />
                ))}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Опубликовать
          </Button>
        </Modal.Footer>
      </Modal>
      <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
        <Container>
          <Navbar.Brand>Админ панель</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link style={{ cursor: "pointer" }} className="nav-link" to="/">Проекты</Link>
              <span style={{ cursor: "pointer" }} className="nav-link" >Блоги</span>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container data-bs-theme="dark">
        <br />
        <h2 style={{ color: 'white' }}>
          Блоги
        </h2>
        <p style={{ color: 'gray' }}>Представленная ниже таблица отображает список всех блогов на сайте.</p>
        <br />
        <Alert show={showSuccess!==undefined ? true : false} variant="warning">
          <Alert.Heading>{showSuccess!==undefined && showSuccess.title}</Alert.Heading>
          <p>
            {showSuccess!==undefined && showSuccess.text}
          </p>
          <hr />
          <div className="d-flex justify-content-end">
            <Button onClick={() => setShowSucces(undefined)} variant="outline-warning">
              Закрыть
            </Button>
          </div>
        </Alert>
        {blogs !== undefined && <Table striped bordered hover>
          <thead>
            <tr>
              <th>Название проекта</th>
              <th>Дата добавления</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((proj, index) =>
              <tr key={index}>
                <td key={index + 1}>{proj.title}</td>
                <td key={index + 2}>{proj.date}</td>
                <td>
                  <Link to={"/edit/" + proj.url}><Button size="sm" variant="outline-primary">Редактировать</Button></Link>
                  <Button size="sm" variant="outline-danger" className='ms-2' onClick={() => callDelete(proj.url, proj.title)}>Удалить</Button>
                </td>
              </tr>
            )}
          </tbody>
        </Table>}
        <Link to="/edit"><Button>+ Новый блог</Button></Link>
      </Container>
    </div>
  )
}
