import React, { useEffect, useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import MarkdownEditor, { underline } from '@uiw/react-markdown-editor';
import { Link, Navigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import latinize from 'latinize';
import { supabase } from '../client'
import Alert from 'react-bootstrap/Alert';

export const Editor = (props) => {
  const [item, setItem] = useState()
  const [blogText, setBlogText] = useState(
    `## Это редактор блогов
Здесь вы можете писать текст. Рекомендуется прочесть документацию,   
чтобы лучше разобраться с форматированием. Для предварительного  
просмотра нажмите иконку глаза.  `)
  const [title, setTitle] = useState('')
  const [blogDate, setBlogDate] = useState('')
  const [mainImg, setMainImg] = useState();
  const [images, setImages] = useState([]);
  const [fakeImages, setFakeImages] = useState([]);
  const [showSuccess, setShowSucces] = useState();
  const [additionImages, setAdditionImages] = useState([])
  const [additionImagesServe, setAdditionImagesServe] = useState([])
  const [isEditing, setIsEditing] = useState(useParams().blogid !== undefined)

  const location = useParams().blogid

  const handleFileChange = (event) => {
    const imageFiles = event.target.files[0];
    const fake = URL.createObjectURL(event.target.files[0]);
    //setAdditionImages([...additionImages, 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/blogs_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + "/" + imageFiles.name.replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_')]);
    setImages([...images, imageFiles]);
    setFakeImages([...fakeImages, fake])
    event.target.value = ''; // Очищаем input
  };
  async function uploadFile(file) {
    if (file !== undefined && file.name !== undefined) {
      const { data, error } = await supabase
        .storage
        .from('blogs_images')
        .upload(latinize(`${latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_')}/${latinize(file.name).replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_')}`), file, {
          cacheControl: '3600',
          upsert: false
        })
      //console.log(`${latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_')}/${file.name.replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_')}`)
    }
  }
  async function getBlog() {
    let { data: blogs, error } = await supabase
      .from('blogs')
      .select('*')
      .eq('url', location)
    if (blogs.length == 0) {
      setItem(null)
    }
    if (blogs.length !== 0) {
      setBlogText(blogs[0].text)
      setItem(blogs)
      setTitle(blogs[0].title)
      setMainImg(blogs[0].main_img)
      setBlogText(blogs[0].text)
      setBlogDate(blogs[0].date)
      setImages(blogs[0].images)
      setFakeImages(blogs[0].images)
    }
  }

  useEffect(() => {
    if (props.type === "edit") {
      getBlog()
    }
  }, [])

  async function uploadBlog() {
    if (title.trim() !== '' && blogDate.trim() !== '' && blogText.trim() !== '' && mainImg) {
      [...images, mainImg].forEach(uploadFile)

      const { data, error } = await supabase
        .from('blogs')
        .insert([
          {
            title: title.trim(),
            text: blogText.trim(),
            url: latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_'),
            date: blogDate.trim(),
            main_img: 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/blogs_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + "/" + latinize(mainImg.name).replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_'),
            images: images.map((item) => 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/blogs_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + "/" + latinize(item.name).replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_'))
          },
        ])
        .select()
      if (!error) {
        alert('Блог успешно опубликован и уже доступен на сайте')
        window.location.replace('/');
      } else {
        setShowSucces({
          title: "Ошибка",
          text: "Что-то пошло не так. Подробнее: " + error.message,
        })
      }
    } else {
      alert("Вы не заполнили все нужные поля")
    }
  }
  async function editBlog() {
    [...images, mainImg].forEach(uploadFile)
    const { data, error } = await supabase
      .from('blogs')
      .update([
        {
          title: title.trim(),
          text: blogText.trim(),
          date: blogDate.trim(),
          main_img: mainImg.name !== undefined ? 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/blogs_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + "/" + latinize(mainImg.name).replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') : mainImg,
          images: images.map((item) => item.name !== undefined ? 'https://rrwcxhpwikhaenwjnzsf.supabase.co/storage/v1/object/public/blogs_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + "/" + latinize(item.name).replace(/[ ?!,@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') : item)
        },
      ])
      .eq('url', item[0].url)
      .select()
    if (!error) {
      alert('Блог успешно отредактирован')
      window.location.replace('/');
    }
  }
  async function removeImg(url, e) {
    e.preventDefault();
    setFakeImages(fakeImages.filter(item => item !== url))
    setImages(images.filter(item => item !== url))
    const { data, error } = await supabase
    .storage
    .from('blogs_images')
    .remove([latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + '/' + url.split('blogs_images/' + latinize(title).replace(/[ ?!,.@#$%^&*(){}[\]:;'"<>/\\|`~+=-]/g, '_') + '/')[1]])
    if(error){
      console.log(error)
    }
  }

  if (props.type !== "edit") {
    return (
      <div>
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
          <Container>
            <Navbar.Brand>Админ панель: Редактор блогов</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Container data-bs-theme="dark">
          <br />
          <Link to="/" style={{ color: 'white' }}>{'< Вернуться'}</Link>
          <br />
          <br />
          <h2 style={{ color: 'white' }}>
            Новый блог
          </h2>
          <p style={{ color: 'gray' }}>Представленная ниже таблица отображает список все проектов на сайте.</p>
          <br />
          <Form data-bs-theme="dark">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ color: "white" }}>Название блога</Form.Label>
              <Form.Control placeholder="Введите название блога" value={title} onChange={(e) => setTitle(e.target.value)} />
              <Form.Text className="text-muted">
                Рекомендуется название длиной не более 60 символов
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label style={{ color: "white" }}>Дата</Form.Label>
              <Form.Control placeholder="Введите дату. Например: 09.06.2024" value={blogDate} onChange={(e) => setBlogDate(e.target.value)} />
              <Form.Text className="text-muted">
                Ручной ввод нужен для того, чтобы вы могли указать дату отличную от текущей
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ color: "white" }}>Текст блога</Form.Label>
              <MarkdownEditor
                value={blogText}
                style={{ minHeight: "400px" }}
                toolbars={[
                  'undo', 'redo', 'bold', 'italic', 'header', 'strike', 'underline', 'quote', 'olist', 'ulist', 'link'
                ]}
                onChange={setBlogText}
              />
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Главное фото</Form.Label>
              <Form.Control type="file" onChange={(e) => setMainImg(e.target.files[0])} />
              {/* <div className="images-to-upload">
                  {fakeImages.map((image, index) => (
                    <img key={index} src={image} alt={`upload-${index}`} style={{ width: '100px', height: '100px', margin: '8px', borderRadius: "3px", objectFit: "cover" }} />
                  ))}
                </div> */}
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Фотографии для галереи</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
              <div className="images-to-upload">
                {fakeImages.map((image, index) => (
                  <img key={index} src={image} alt={`upload-${index}`} style={{ width: '100px', height: '100px', margin: '8px', borderRadius: "3px", objectFit: "cover" }} />
                ))}
              </div>
              <Form.Text className="text-muted">
                Необязательно
              </Form.Text>
            </Form.Group>
            <br />
            <br />
            <Form.Group className="mb-3">
              <Button variant="primary" onClick={uploadBlog}>
                Опубликовать
              </Button>
            </Form.Group>
          </Form>
          <Alert show={showSuccess !== undefined ? true : false} variant="warning">
            <Alert.Heading>{showSuccess !== undefined && showSuccess.title}</Alert.Heading>
            <p>
              {showSuccess !== undefined && showSuccess.text}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShowSucces(undefined)} variant="outline-warning">
                Закрыть
              </Button>
            </div>
          </Alert>
        </Container>
      </div >
    )
  } else {
    return (
      <div>
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
          <Container>
            <Navbar.Brand>Админ панель: Редактор блогов</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {item !== undefined && item !== null ? <Container>
          <br />
          <Link to="/" style={{ color: 'white' }}>{'< Вернуться'}</Link>
          <br />
          <br />
          <h2 style={{ color: 'white' }}>
            {title}
          </h2>
          <Alert show={showSuccess !== undefined ? true : false} variant="warning">
            <Alert.Heading>{showSuccess !== undefined && showSuccess.title}</Alert.Heading>
            <p>
              {showSuccess !== undefined && showSuccess.text}
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              <Button onClick={() => setShowSucces(undefined)} variant="outline-warning">
                Закрыть
              </Button>
            </div>
          </Alert>
          <p style={{ color: 'gray' }}>Представленная ниже таблица отображает список все проектов на сайте.</p>
          <br />
          <Form data-bs-theme="dark">
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label style={{ color: "white" }}>Название блога</Form.Label>
              <Form.Control placeholder="Введите название блога" value={title} onChange={(e) => setTitle(e.target.value)} />
              <Form.Text className="text-muted">
                Рекомендуется название длиной не более 60 символов
              </Form.Text>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label style={{ color: "white" }}>Дата</Form.Label>
              <Form.Control placeholder="Введите дату. Например: 09.06.2024" value={blogDate} onChange={(e) => setBlogDate(e.target.value)} />
              <Form.Text className="text-muted">
                Ручной ввод нужен для того, чтобы вы могли указать дату отличную от текущей
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label style={{ color: "white" }}>Текст блога</Form.Label>
              <MarkdownEditor
                value={blogText}
                style={{ minHeight: "400px" }}
                toolbars={[
                  'undo', 'redo', 'bold', 'italic', 'header', 'strike', 'underline', 'quote', 'olist', 'ulist', 'link'
                ]}
                onChange={setBlogText}
              />
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Главное фото</Form.Label>
              <Form.Control type="file" onChange={(e) => setMainImg(e.target.files[0])} />
              <Form.Text className="text-muted">
                Если вы выберите новый файл, то он заменит текущее главное фото на новое выбранное
              </Form.Text>
            </Form.Group>
            <Form.Group style={{ color: 'white' }} className="mb-3" controlId="formBasicEmail">
              <Form.Label>Фотографии для галереи</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={handleFileChange} />
              <div className="images-to-upload">
                {fakeImages !== null && fakeImages.map((image, index) => (
                  <div className="img-card">
                    <button className='remove-img' onClick={(e) => removeImg(image, e)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /><line x1="10" y1="11" x2="10" y2="17" /><line x1="14" y1="11" x2="14" y2="17" /></svg>
                    </button>
                    <img key={index} src={image} alt={`upload-${index}`} style={{ width: '100px', height: '100px', margin: '8px', borderRadius: "3px", objectFit: "cover" }} />
                  </div>
                ))}
              </div>
            </Form.Group>
            <br />
            <br />
            <Form.Group className="mb-3">
              <Button variant="primary" onClick={editBlog}>
                Опубликовать
              </Button>
            </Form.Group>
          </Form>
        </Container> : ""}
      </div >
    )
  }
}
