import logo from './logo.svg';
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { Login } from './pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Manage } from './pages/Manage';
import { useEffect, useState } from 'react';
import { Manage_Blogs } from './pages/Manage_Blogs';
import { Editor } from './pages/Editor';
import { supabase } from './client';

function App() {
  //const [currentManage, setCurrentManage] = useState(location.pathname)
  const [isLogined, setIsLogined] = useState(undefined)
  const location = useLocation()

  async function getUser(){
    const { data: { user } } = await supabase.auth.getUser()
    setIsLogined(user)
  }
  useEffect(() => {
    getUser()
  }, [])

  return (
    <div className="App">
      {isLogined!==undefined && isLogined==null && <Navigate to="/login"/>}
      <Routes>
        <Route path='/' exact element={<Manage/>} />
        <Route path='/blogs' exact element={<Manage_Blogs/>} />
        <Route path='/login' exact element={isLogined==null ? <Login/> : <Navigate to="/"/>}/>
        <Route path='/edit/:blogid' exact element={<Editor type="edit"/>} />
        <Route path='/edit' exact element={<Editor/>} />
      </Routes>
      <img src='/bg.svg' className='bg-img'/>
    </div>
  );
}

export default App;
