import React, { useState } from 'react'
import { Card, CardBody } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { supabase } from '../client';


export const Login = () => {
  const[email, setEmail] = useState('')
  const[password, setPassword] = useState('')

  async function logIn(){
    let { data, error } = await supabase.auth.signInWithPassword({
      email: email.trim(),
      password: password.trim()
    })
    if(error){
      alert("Не удалось войти в аккаунт. Возможно, email или пароль неверны")
    } else {
      window.location.reload()
    }
  }

  return (
    <div className="page">
      <div className="login-page-center">
        <Card style={{ width: '500px' }}>
          <CardBody>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Введите email" value={email} onChange={(e) => setEmail(e.target.value)}/>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Пароль</Form.Label>
                <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Введите пароль" />
              </Form.Group>
              <Button variant="primary" onClick={logIn}>
                Войти
              </Button>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
